import { withPrefix } from "gatsby"
import React from "react"
import INSTEPPopover from "./instep-popover"

const Dimension = ({ itm }) => {
  return (
    <INSTEPPopover
      text={itm.description}
      placement="top"
      key={itm.id}
      title={itm.dimension}
      icon={itm.icon}
    >
      <div
        className="dimension p-1 m-0 d-flex flex-column justify-content-center"
        style={{ backgroundColor: itm.color }}
        tabIndex="0"
      >
        <div className="mb-0">
          <img src={withPrefix(`/images/icons/${itm.icon}.svg`)} alt="" />
        </div>
        {["Central Statistical Ideas"].indexOf(itm.dimension) < 0 ? (
          <div className="text-white pt-1">{itm.dimension}</div>
        ) : (
          <div className="text-black pt-1">{itm.dimension}</div>
        )}
      </div>
    </INSTEPPopover>
  )
}

export default Dimension
