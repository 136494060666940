import React from "react"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import { Col, Container, Image, Row } from "react-bootstrap"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Dimension from "../components/dimension"
import { FaArrowRight, FaCircle } from "react-icons/fa"
import Screens from "../components/screen-carousel"
import TeamCarousel from "../components/team-carousel"
import NavBar from "../components/NavBar"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allTeamCsv {
        nodes {
          image
          name
          title
          role
          link
        }
      }
      allDimensionsCsv {
        nodes {
          id
          description
          dimension
          color
          icon
        }
      }
    }
  `)
  const team = data.allTeamCsv.nodes
  const dimensions = data.allDimensionsCsv.nodes

  const isTouchDevice = () => {
    if (typeof window !== "undefined") {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      )
    }
  }

  return (
    <Layout>
      <SEO title="InSTEP" />
      <div className="hero-header background-indigo text-white">
        <NavBar linkTitle="About InSTEP" path={"/about"} />
        <Container>
          <Row className="align-items-center">
            <Col md={6} lg={5} className="pt-2 pr-2 pr-md-3 pr-lg-4">
              {/* <img
                src={withPrefix("/images/logos/nc_logo.svg")}
                alt="NC State Logo"
                className="nc-state-logo"
              /> */}
              <div className="text-md-left text-center">
                <p className="mt-3 mt-md-0 mt-lg-0">
                  <img
                    src={withPrefix("/images/logos/logo.svg")}
                    alt="InSTEP Logo"
                  />
                </p>
                <p className="hero-content">
                  <span className="font-weight-bold">Free</span> personalized
                  professional learning to support{" "}
                  <span className="font-weight-bold">teachers</span> and{" "}
                  <span className="font-weight-bold">
                    instructional coaches
                  </span>{" "}
                  in developing expertise in teaching statistics and data
                  science in grades 6-12.
                </p>
                <p>
                  <a
                    href={withPrefix("/about")}
                    className="hero-header-cta d-flex align-items-center"
                  >
                    Learn More About InSTEP <FaArrowRight className="pl-1" />
                  </a>
                </p>
              </div>
            </Col>
            <Col lg={7} md={6} className="px-0">
              <img
                src={withPrefix("/images/Hero@2x.jpg")}
                alt="Hero"
                // height="510"
                height="450"
                className="d-none d-md-block"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="d-block d-md-none">
        <img
          src={withPrefix("/images/HeroMobile@2x.png")}
          alt="Hero"
          className="w-100"
        />
      </div>
      <div>
        <Container>
          <Row>
            <Col className="text-center pt-3 pt-md-5 mb-0 pb-md-2">
              <Image
                className="d-none d-md-block w-70 mx-auto"
                src={withPrefix("/images/InSTEP_Diagram.svg")}
                fluid
                alt="InSTEP: Invigorating Statistics and Data Science Technology through Professional Learning"
                style={{ maxWidth: "755px" }}
              />
              <Image
                className="d-block d-md-none w-100"
                src={withPrefix("/images/InSTEP_Diagram_Mobile.svg")}
                fluid
                alt="InSTEP: Invigorating Statistics and Data Science Technology through Professional Learning"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="background-indigo-lightest pb-3 pt-2 pt-sm-0 mt-3 mt-sm-0">
        <svg
          className="w-100 position-absolute d-none d-sm-block"
          viewBox="0 0 500 100"
          style={{ zIndex: 0 }}
        >
          <path
            d="M 0 30 C 200 70, 300 70, 500 30"
            stroke="transparent"
            fill="white"
          />
          <rect x="0" y="0" width="500" height="30" fill="white" />
        </svg>
        <div style={{ zIndex: 1 }} className="position-relative">
          <Container>
            <Screens />
          </Container>
        </div>
      </div>
      <div className="offset-boxes duotone">
        <div className="left-box d-none d-md-block"></div>
        <div className="right-box d-none d-md-block"></div>
        <Container className="text-white mission" fluid="md">
          <Row>
            <Col md={4}>
              <div className="mission-box pb-2 pl-md-0 px-0 px-sm-3">
                <h3 className="text-white">Our Mission</h3>
                <p>
                  Improve classrooms to build a better tomorrow for the
                  data-centric world our students live in.
                </p>
              </div>
            </Col>
            <Col md={{ span: 7, offset: 1 }} className="quote">
              <div className="pt-3 pb-2 px-sm-3 px-0 pt-md-5">
                <p className="text-center text-md-left mt-n4 mt-md-0">
                  <img
                    src={withPrefix("/images/icons/icon_quotes.svg")}
                    alt=""
                  />
                </p>
                <p className="my-0">
                  I liked being able to go through and find what works for me
                  and my students. What do I need? What do my students need?
                </p>
                <p style={{ color: "#6E87C5" }}>
                  Middle School Mathematics and Science Teacher
                </p>
                <p className="text-center text-md-left mt-0">
                  <img
                    src={withPrefix("/images/icons/icon_quotes.svg")}
                    alt=""
                  />
                </p>
                <p className="my-0">
                  It was nice having a package of things that I could sort and
                  sift through in a sequence that made sense to me
                </p>
                <p style={{ color: "#6E87C5" }}>
                  High School Mathematics Teacher
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="my-3 my-md-5 dimension-section">
        <Row>
          <Col xl={4}>
            <h3 className="dimension-section-header">
              Built on 7 Dimensions of Teaching Statistics and Data Science
            </h3>
            <p className="dimension-section-content">
              Modules for teachers’ learning are organized by 7 interrelated
              dimensions of instructional design that support developing
              students' understanding of foundational processes, practices, and
              ways of thinking used in statistics and data science classrooms.
            </p>
            <p className="d-flex">
              <strong>
                {isTouchDevice() ? "Tap on" : "Hover over"} each dimension to
                learn more
              </strong>
              <svg
                width="65.467"
                height="15.539"
                viewBox="0 0 65.467 15.539"
                className="d-none d-xl-block float-right mt-3"
              >
                <g id="icon_arrow" transform="translate(0 2.27) rotate(-2)">
                  <g id="Group_14644" data-name="Group 14644">
                    <path
                      id="Path_250199"
                      data-name="Path 250199"
                      d="M57.765,10.949c.521-.847,1.034-1.7,1.529-2.564-1,.442-2.017.867-3.029,1.232a59.656,59.656,0,0,1-8.615,2.338,68.169,68.169,0,0,1-16.977,1.222,66.511,66.511,0,0,1-16.727-3.065C8.765,8.439,3.257,6.267.146,1.573-.4.744.717-.441,1.554.166c8.6,6.228,19.218,9.157,29.756,9.59A64.563,64.563,0,0,0,47.341,8.439a69.829,69.829,0,0,0,7.381-2.022c1.605-.532,3.217-1.14,4.841-1.671a27.226,27.226,0,0,1-6.047-1.8,1.476,1.476,0,0,1,.74-2.731c3.337.286,6.413,1.915,9.682,2.546A1.513,1.513,0,0,1,64.986,4.6,23.09,23.09,0,0,1,60.03,12.7c-1.09,1.229-3.12-.361-2.265-1.746"
                      transform="translate(0 0.001)"
                      fill="#344891"
                    />
                  </g>
                </g>
              </svg>
            </p>
          </Col>
          <Col xl={8}>
            <div className="d-flex d-md-none justify-content-md-end justify-content-center">
              <div className="d-flex flex-column mt-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="78.955"
                  height="73.818"
                  viewBox="0 0 78.955 73.818"
                  style={{ margin: "0 auto", paddingBottom: "1rem" }}
                >
                  <g
                    id="Group_14788"
                    data-name="Group 14788"
                    transform="matrix(0.848, 0.53, -0.53, 0.848, 26.067, -9.325)"
                  >
                    <path
                      id="Path_1240"
                      data-name="Path 1240"
                      d="M52.957,43.881c1.025-.262,1.677-1.12,2.822-1.655-.467,2.119-1.433,3.9-1.746,5.836a31.552,31.552,0,0,0-.271,5.969.711.711,0,0,0,.521-.659,4.787,4.787,0,0,1,.949-2.023c.9-1.264,1.5-2.684,2.355-3.953a10.49,10.49,0,0,1,1.529-2.062A7.213,7.213,0,0,0,60.428,43.5c.47-.838,1.392-1.376,1.673-2.327.6-.009.652-.578.971-.871.418-.382.284-.776-.139-1.08a6.8,6.8,0,0,0-1.527-.885,1.591,1.591,0,0,1-.958-.657c-.214-.373-.785-.306-1.2-.383a21.121,21.121,0,0,1-7.092-2.524,31.5,31.5,0,0,0-5.347-2.17,8.746,8.746,0,0,0-2.009-.117c-.372-.046-.809.665-.759,1.135a1.911,1.911,0,0,0,.7,1.4,33.758,33.758,0,0,0,6.381,3.945c.562.271,1.134.52,1.755.8-.219.442-.619.536-.974.688a27.92,27.92,0,0,1-14.937,1.916,33.13,33.13,0,0,1-15.134-5.533,38.106,38.106,0,0,1-6.078-5.186c-.886-.963-1.875-1.84-2.732-2.823A38.32,38.32,0,0,1,5.126,15.711,38.713,38.713,0,0,1,2.993,4.351a17.643,17.643,0,0,0-.34-3.065A1.786,1.786,0,0,0,2.241.312.834.834,0,0,0,.85.445a3.4,3.4,0,0,0-.478,1.3,23.739,23.739,0,0,0,.217,9.5A81.9,81.9,0,0,0,3.752,21.525,27.873,27.873,0,0,0,7.329,27.4,56.357,56.357,0,0,0,16.6,37.127a33.538,33.538,0,0,0,8.432,5.182c.985.412,2.046.757,2.945,1.241,1.275.686,2.754.576,3.98,1.277a2.826,2.826,0,0,0,1.2.211,23.576,23.576,0,0,0,3.069.381c.689.71,1.535.5,2.37.36.963.778,1.99.069,2.98.07a6.762,6.762,0,0,1,2.345.325c.137.05.291.172.393.088.845-.689,1.9-.3,2.837-.52a2.761,2.761,0,0,0,.835-.3A19.514,19.514,0,0,0,50.748,43.9c.068-.058.27-.092.3-.059.645.828.647-.076.9-.294.107-.094.207-.194.354-.331l.7.671Z"
                      transform="matrix(0.985, -0.174, 0.174, 0.985, 0, 10.996)"
                      fill="#344891"
                    />
                    <path
                      id="Path_1241"
                      data-name="Path 1241"
                      d="M.132.005c.01.163-.247.289-.068.47C.085.5.185.48.213.449.4.249.148.148.1,0Z"
                      transform="translate(59.282 50.682)"
                      fill="#344891"
                    />
                  </g>
                </svg>

                <Dimension itm={dimensions[0]} />
                <Dimension itm={dimensions[2]} />
                <Dimension itm={dimensions[4]} />
              </div>
              <div className="d-flex flex-column">
                <Dimension itm={dimensions[1]} />
                <Dimension itm={dimensions[3]} />
                <Dimension itm={dimensions[5]} />
                <Dimension itm={dimensions[6]} />
              </div>
            </div>

            <div className="justify-content-xl-end justify-content-center d-none d-md-flex">
              <div className="d-flex justify-content-start flex-column pt-xl-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="78.955"
                  height="73.818"
                  viewBox="0 0 78.955 73.818"
                  style={{ margin: "0 auto", paddingBottom: "1rem" }}
                  className="d-block d-xl-none"
                >
                  <g
                    id="Group_14788"
                    data-name="Group 14788"
                    transform="matrix(0.848, 0.53, -0.53, 0.848, 26.067, -9.325)"
                  >
                    <path
                      id="Path_1240"
                      data-name="Path 1240"
                      d="M52.957,43.881c1.025-.262,1.677-1.12,2.822-1.655-.467,2.119-1.433,3.9-1.746,5.836a31.552,31.552,0,0,0-.271,5.969.711.711,0,0,0,.521-.659,4.787,4.787,0,0,1,.949-2.023c.9-1.264,1.5-2.684,2.355-3.953a10.49,10.49,0,0,1,1.529-2.062A7.213,7.213,0,0,0,60.428,43.5c.47-.838,1.392-1.376,1.673-2.327.6-.009.652-.578.971-.871.418-.382.284-.776-.139-1.08a6.8,6.8,0,0,0-1.527-.885,1.591,1.591,0,0,1-.958-.657c-.214-.373-.785-.306-1.2-.383a21.121,21.121,0,0,1-7.092-2.524,31.5,31.5,0,0,0-5.347-2.17,8.746,8.746,0,0,0-2.009-.117c-.372-.046-.809.665-.759,1.135a1.911,1.911,0,0,0,.7,1.4,33.758,33.758,0,0,0,6.381,3.945c.562.271,1.134.52,1.755.8-.219.442-.619.536-.974.688a27.92,27.92,0,0,1-14.937,1.916,33.13,33.13,0,0,1-15.134-5.533,38.106,38.106,0,0,1-6.078-5.186c-.886-.963-1.875-1.84-2.732-2.823A38.32,38.32,0,0,1,5.126,15.711,38.713,38.713,0,0,1,2.993,4.351a17.643,17.643,0,0,0-.34-3.065A1.786,1.786,0,0,0,2.241.312.834.834,0,0,0,.85.445a3.4,3.4,0,0,0-.478,1.3,23.739,23.739,0,0,0,.217,9.5A81.9,81.9,0,0,0,3.752,21.525,27.873,27.873,0,0,0,7.329,27.4,56.357,56.357,0,0,0,16.6,37.127a33.538,33.538,0,0,0,8.432,5.182c.985.412,2.046.757,2.945,1.241,1.275.686,2.754.576,3.98,1.277a2.826,2.826,0,0,0,1.2.211,23.576,23.576,0,0,0,3.069.381c.689.71,1.535.5,2.37.36.963.778,1.99.069,2.98.07a6.762,6.762,0,0,1,2.345.325c.137.05.291.172.393.088.845-.689,1.9-.3,2.837-.52a2.761,2.761,0,0,0,.835-.3A19.514,19.514,0,0,0,50.748,43.9c.068-.058.27-.092.3-.059.645.828.647-.076.9-.294.107-.094.207-.194.354-.331l.7.671Z"
                      transform="matrix(0.985, -0.174, 0.174, 0.985, 0, 10.996)"
                      fill="#344891"
                    />
                    <path
                      id="Path_1241"
                      data-name="Path 1241"
                      d="M.132.005c.01.163-.247.289-.068.47C.085.5.185.48.213.449.4.249.148.148.1,0Z"
                      transform="translate(59.282 50.682)"
                      fill="#344891"
                    />
                  </g>
                </svg>
                <Dimension itm={dimensions[0]} />
              </div>
              <div>
                <Dimension itm={dimensions[1]} />
                <Dimension itm={dimensions[2]} />
              </div>
              <div className="mt-3">
                <Dimension itm={dimensions[3]} />
                <Dimension itm={dimensions[4]} />
              </div>
              <div>
                <Dimension itm={dimensions[5]} />
                <Dimension itm={dimensions[6]} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div
        className="background-indigo-dark instep-available-section text-white py-3 py-md-5"
        id="notify"
      >
        <Container>
          <Row>
            <Col lg={4} className="text-center text-lg-left">
              <h1 className="text-white mb-3">Interested in a Preview?</h1>
              <p>
                Want to see what the InSTEP platform has to offer? Check out a
                few sample materials to get a sense of the learning experiences
                you can expect when you sign up!
              </p>
            </Col>
            <Col
              lg={3}
              className="d-none d-lg-block text-center"
              style={{ marginTop: "25px" }}
            >
              <img src={withPrefix("images/icons/arrows.svg")} alt="" />
            </Col>
            <Col lg={{ span: 4 }} className=" align-items-center samples">
              <OutboundLink
                href="https://instep-assets.s3.amazonaws.com/shared/samples/D%26S%20E4/D%26S_E4.html"
                target="_blank"
                rel="noreferrer"
              >
                <div className="sample data-statistics d-flex justify-content-between">
                  <div>
                    <div className="title">Data and Statistical Practices</div>
                    <div className="type">Module Preview</div>
                  </div>
                  <div className="icon d-none d-xl-block">
                    <FaCircle className="h-100" />
                  </div>
                </div>
              </OutboundLink>
              <OutboundLink
                href="https://instep-assets.s3.amazonaws.com/shared/samples/Data%20E4/Data_E4.html"
                target="_blank"
                rel="noreferrer"
              >
                <div className="sample data d-flex justify-content-between">
                  <div>
                    <div className="title">Data</div>
                    <div className="type">Module Preview</div>
                  </div>
                  <div className="icon d-none d-xl-block">
                    <FaCircle className="h-100" />
                  </div>
                </div>
              </OutboundLink>
              <OutboundLink
                href="https://instep-assets.s3.amazonaws.com/shared/samples/Tasks%20E4/Task_E4_v2.html"
                target="_blank"
                rel="noreferrer"
              >
                <div className="sample tasks d-flex justify-content-between">
                  <div>
                    <div className="title">Tasks</div>
                    <div className="type">Module Preview</div>
                  </div>
                  <div className="icon d-none d-xl-block">
                    <FaCircle className="h-100" />
                  </div>
                </div>
              </OutboundLink>

              <OutboundLink
                href="https://instep-assets.s3.amazonaws.com/shared/samples/roller_coaster_investigation_lesson_plan.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <div className="sample data-investigation d-flex justify-content-between">
                  <div>
                    <div className="title">
                      Roller Coaster Data Investigation
                    </div>
                    <div className="type">Classroom Ready Lesson Plan</div>
                  </div>
                  <div className="icon d-none d-xl-block">
                    <FaCircle className="h-100" />
                  </div>
                </div>
              </OutboundLink>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-3 py-md-5">
        <Row>
          <Col>
            <h1 className="text-center mb-4 project-team-header">
              Project Team
            </h1>
            <TeamCarousel>
              {team.map(itm => {
                return (
                  <div
                    className="carouselItem position-relative px-2"
                    key={itm.name}
                  >
                    <Image
                      width="130"
                      src={withPrefix(itm.image)}
                      alt={itm.name}
                    />
                    <h4>
                      <a href={itm.link} target="_blank" rel="noreferrer">
                        {itm.name}
                      </a>
                    </h4>
                    <p>{itm.role}</p>
                    <p>{itm.title}</p>
                  </div>
                )
              })}
            </TeamCarousel>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
