import React from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"

const SampleNextArrow = props => {
  const { className, onClick } = props
  return <div onClick={onClick} className={className}></div>
}

const SamplePrevArrow = props => {
  const { className, onClick } = props
  return <div onClick={onClick} className={className}></div>
}

const TeamCarousel = ({ children }) => {
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return <Slider {...settings}>{children}</Slider>
}

TeamCarousel.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TeamCarousel
